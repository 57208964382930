.box {
  max-height: 436.5px;
  width: 340px;
  margin-left: auto;
  margin-right: auto;
  overflow-y: scroll;
  overflow-x: hidden;
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(255, 255, 255, 1);
  padding: 20px 5px 5px 17px;
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.7);
    border-radius: 6px;
    margin: 35px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-red);
    border-radius: 6px;
  }
  @media screen and (min-width: 430px) {
    width: 100%;
  }

  @media screen and (min-width: 566px) {
    padding: 30px 40px 5px 40px;
  }

  &__header {
    font-weight: var(--font-primary-bold);
    font-size: var(--font-size-l);
    line-height: var(--line-height-l);
    text-align: center;
    margin-bottom: 30px;
  }
}

h3 {
  margin: 10px 0;
  text-align: center;
}

ul {
  margin-left: 30px;
  list-style: none;
}
