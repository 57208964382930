.list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin-left: auto;
  margin-right: auto;

  @media screen and (min-width: 996px) {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 678px;
  }

  &__item {
    list-style: none;
    margin-top: 35px;
    margin-bottom: 35px;
    cursor: pointer;

    @media screen and (min-width: 996px) {
      margin-top: 0;
      margin-bottom: 0;
    }

    &Link {
      text-decoration: none;
      color: var(--color-dark);
      font-size: var(--font-size-md);
      line-height: var(--line-height-md);
      font-weight: var(--font-primary-bold);
      letter-spacing: 0.01em;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
