.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 165px;
  height: 50px;
  background: var(--color-red);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  color: var(--color-white);
  font-weight: var(--font-primary-bold);
  font-size: var(--font-size-md);
  text-align: center;
  text-decoration: none;
  border: none;
  outline-style: none;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  @media screen and (min-width: 730px) {
    width: 292px;
  }

  &Secondary {
    @extend .button;

    width: 145px;
    background: var(--color-white);
    color: var(--color-gray);

    @media screen and (min-width: 730px) {
      width: 292px;
    }
  }
}
