.description {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 130px;

  @media screen and (min-width: 490px) {
    margin-top: 0;
  }

  @media screen and (min-width: 660px) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 40px;
  }

  &Offer {
    @extend .description;

    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;
    margin-top: 40px;

    @media screen and (min-width: 1040px) {
      justify-content: space-between;
      margin-top: 80px;
    }
  }

  &__box {
    max-width: 785px;
  }

  &__photo {
    min-width: 222px;
    min-height: 222px;
    box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.25);
    margin-top: 0;
    margin-right: 0;
    border-radius: 50%;
    border: 6px solid #ffffff;

    @media screen and (min-width: 660px) {
      margin-right: 3rem;
    }

    @media screen and (min-width: 996px) {
      min-width: 387px;
      min-height: 387px;
    }

    &Small {
      width: 190px;
      height: 190px;
      border-radius: 50%;
      border: 6px solid #ffffff;
      box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.25);
      margin: 0 5px 5px 5px;
    }
  }

  &__paragraph {
    font-weight: var(--font-primary-regular);
    font-size: var(--font-size-md);
    line-height: var(--line-height-md);
    margin-bottom: 20px;
  }

  &__service {
    text-align: center;
    font-weight: var(--font-primary-bold);
    font-size: var(--font-size-md);
    line-height: var(--line-height-md);
    color: var(--color-gray);
    width: 200px;

    &Paragraph {
      @extend .description__service;

      font-weight: var(--font-primary-regular);
      font-size: var(--font-size-s);
      line-height: var(--line-height-s);
      margin-bottom: 30px;
    }
  }

  &__title {
    font-weight: var(--font-primary-bold);
    font-size: var(--font-size-xl);
    line-height: var(--line-height-xl);
    margin-bottom: 1rem;
    text-align: left;
  }

  &__subtitle {
    font-weight: var(--font-primary-bold);
    font-size: var(--font-size-mx);
    line-height: var(--line-height-mx);
    margin-bottom: 0.5rem;
    text-align: left;
  }
}
